<template>
  <form>
    <div class="row mt-3 mb-3" v-if="message">
      <div class="col-12">
        <small class="text-danger" v-if="isError">{{ message }}</small>
        <small class="text-success" v-else>{{ message }}</small>
      </div>
    </div>
    <div v-if="step == 1">
      <div class="form-group">
        <label for="vCode">Voucher Code</label>
        <input class="form-control" id="vCode" placeholder="Enter Voucher code" type="text" v-model="form.vCode" />
        <small v-if="errors && errors.price" class="text-danger">
          {{ errors.price[0] }}
        </small>
      </div>
    </div>
    <div v-if="step == 2">
      <div class="form-group">
        <label for="vOTP">One verification code (OTP)</label>
        <input class="form-control" id="vOTP" placeholder="Enter verification code" type="text" v-model="form.vOTP" />
        <small v-if="errors && errors.price" class="text-danger">
          {{ errors.price[0] }}
        </small>
      </div>
    </div>
    <button v-if="step === 0" class="btn btn-primary ls-button mt-3 mb-3" @click.prevent="nextStep()">
      <span v-if="loading" class="spinner-border" role="status">
        <span class="sr-only">Processing Payment...</span>
      </span>
      <span v-else> Pay with Pahukama Voucher </span>
    </button>
    <button v-if="step === 1" class="btn btn-primary ls-button mt-3 mb-3" @click.prevent="nextStep()">
      <span v-if="loading" class="spinner-border" role="status">
        <span class="sr-only">Processing Payment...</span>
      </span>
      <span v-else> Send OTP </span>
    </button>
    <button v-if="step === 2" class="btn btn-primary ls-button mt-3 mb-3" @click.prevent="processPayment()">
      <span v-if="loading" class="spinner-border" role="status">
        <span class="sr-only">Processing Payment...</span>
      </span>
      <span v-else> Verify OTP </span>
    </button>
  </form>
</template>

<script>
import axios from "axios";

export default {
  name: "Voucher",
  props: {
    order: Object,
  },
  data() {
    return {
      phone_number: null,
      email: null,
      defaultOption: "ecocash",
      message: null,
      paynowOption: "ecocash",
      loading: false,
      waiting: false,
      buttonText: "Waiting...",
      isError: false,
      errors: null,
      optReqSent: false,
      step: 1,
      form: {
        vOTP: "",
        vCode: "",
        otpPhone: "",
      },
    };
  },
  methods: {
    nextStep() {
      switch (this.step) {
        case 0:
          this.step += 1;
          break;
        case 1:
          // Send OTP
          this.loading = true;
          this.$loading(true);
          axios
            .post("user/initiate_redeem_voucher", this.form)
            .then((response) => {
              // If OTP sending is successful, show OTP input field
              this.$toasted.show(response.data.message, { duration: 20000 });
              this.step = 2;
            })
            .catch((error) => {
              this.$toasted.show(error.response.data, { duration: 20000 });
              this.isError = true;
            })
            .finally(() => {
              this.loading = false;
              this.$loading(false);
            });
          break;
        default:
          this.step = 0;
          break;
      }
    },
    processPayment() {
      this.loading = true;
      this.$loading(true);

      axios
        .post(`user/complete_redeem_voucher/${this.$route.params.id}`, this.form)
        .then((response) => {
          if (response.data.message) {
            this.message = response.data.message;
            this.$store.dispatch("cart/clearCart");
            this.$toasted.show(response.data.message, { duration: 20000 });
          }

          if (response.data.status === "Pending" && response.data.redirectUrl) {
            document.location.href = response.data.redirectUrl;
          }

          this.$emit("payment-result", { isPaid: false });
        })
        .catch((error) => {
          if (error.response && error.response.status === 422) {
            this.message = error.response.data;
            this.$toasted.show(this.message, { duration: 20000 });
            this.errors = error.response.data.errors;
          } else {
            this.message = error.backendErrors ? error.backendErrors[0] : "An error occurred.";
            this.$toasted.show(error.response.data, { duration: 20000 });
          }
          this.isError = true;
        })
        .finally(() => {
          this.loading = false;
          this.$loading(false);
        });
    }
  },
};
</script>

<style scoped>
.ps-block__content {
  padding: 30px 20px;
  margin-bottom: 10px;
  border-radius: 4px;
  border: 2px solid #eaeaea;
}

.container-fluid {
  background-color: #c5cae9;
}

.heading {
  font-size: 40px;
  margin-top: 35px;
  margin-bottom: 30px;
  padding-left: 20px;
}

.card {
  border-radius: 10px !important;
  margin-top: 60px;
  margin-bottom: 60px;
}

.form-card {
  margin-left: 20px;
  margin-right: 20px;
}

.form-card input,
.form-card textarea {
  padding: 10px 15px 5px 15px;
  border: none;
  border: 1px solid lightgrey;
  border-radius: 6px;
  margin-bottom: 25px;
  margin-top: 2px;
  width: 100%;
  box-sizing: border-box;
  font-family: arial;
  color: #2c3e50;
  font-size: 14px;
  letter-spacing: 1px;
}

.form-card input:focus,
.form-card textarea:focus {
  -moz-box-shadow: 0px 0px 0px 1.5px skyblue !important;
  -webkit-box-shadow: 0px 0px 0px 1.5px skyblue !important;
  box-shadow: 0px 0px 0px 1.5px skyblue !important;
  font-weight: bold;
  border: 1px solid #304ffe;
  outline-width: 0;
}

.input-group {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.input-group input {
  position: relative;
  height: 80px;
  margin-left: 1px;
  margin-right: 1px;
  border-radius: 6px;
  padding-top: 30px;
  padding-left: 25px;
}

.input-group label {
  position: absolute;
  height: 24px;
  background: none;
  border-radius: 6px;
  line-height: 48px;
  font-size: 15px;
  color: gray;
  width: 100%;
  font-weight: 100;
  padding-left: 25px;
}

input:focus+label {
  color: #304ffe;
}

.btn-pay {
  background-color: #304ffe;
  height: 60px;
  color: #ffffff !important;
  font-weight: bold;
}

.btn-pay:hover {
  background-color: #3f51b5;
}

.fit-image {
  width: 100%;
  object-fit: cover;
}

img {
  border-radius: 5px;
}

.radio-group {
  position: relative;
  margin-bottom: 25px;
}

.radio {
  display: inline-block;
  border-radius: 6px;
  box-sizing: border-box;
  border: 2px solid lightgrey;
  cursor: pointer;
  margin: 12px 25px 12px 0px;
}

.radio:hover {
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.2);
}

.radio.selected {
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 155, 0.4);
  border: 2px solid blue;
}

.label-radio {
  font-weight: bold;
  color: #000000;
}
</style>
