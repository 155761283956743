<template>
  <div class="row justify-content-center mb-4 radio-group">
    <div class="col-sm-3 col-5" v-for="paymentMethod in paymentMethods">
      <div
        @click.prevent="changeSelectedMethod(paymentMethod.slug)"
        :class="
          selectedMethod === paymentMethod.slug
            ? 'radio selected mx-auto'
            : 'radio mx-auto'
        "
        data-value="dk"
      >
        <div v-if="paymentMethod.slug === 'pay-now'">
          <img
            alt="Paynow"
            class="fit-image ls-contain-object-fit"
            src="/img/paynow.png"
            width="150px"
            height="75px"
          />
          <p class="ls-center">PayNow</p>
        </div>

        <div v-else-if="paymentMethod.slug === 'braintree'">
          <img
            class="fit-image ls-contain-object-fit"
            alt="Braintree"
            src="https://iconape.com/wp-content/files/ty/204660/svg/204660.svg"
            width="150px"
            height="75px"
          />
          <p class="ls-center">Braintree</p>
        </div>

        <div v-else-if="paymentMethod.slug === 'stripe'">
          <img
            alt="Stripe"
            class="fit-image ls-contain-object-fit"
            src="/img/stripe-payment.png"
            width="150px"
            height="75px"
          />
          <p class="ls-center">Stripe</p>
        </div>

        <div v-else-if="paymentMethod.slug === 'paypal'">
          <img
            alt="Paypal"
            class="fit-image ls-contain-object-fit"
            src="https://i.imgur.com/cMk1MtK.jpg"
            width="150px"
            height="75px"
          />
          <p class="ls-center">PayPal</p>
        </div>

        <div v-else-if="paymentMethod.slug === 'contipay'">
          <img
            src="/img/conti.jpeg"
            class="fit-image ls-contain-object-fit"
            alt="ContiPay"
            width="150px"
            height="75px"
          />
          <p class="ls-center">ContiPay</p>
        </div>

        <div v-else-if="paymentMethod.slug === 'voucher'">
          <img
            src="/img/pahukama voucher logo.png"
            class="fit-image ls-contain-object-fit"
            alt="Voucher"
            width="150px"
            height="75px"
          />
          <p class="ls-center">Voucher</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PaymentOptionsTab",
  props: {
    paymentMethods: Array,
    selectedMethod: String,
  },

  methods: {
    changeSelectedMethod(selectedMethod) {
      this.$emit("open-payment-method", { selectedMethod });
    },
  },
};
</script>

<style scoped></style>
